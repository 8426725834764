import styles from "./styles.module.css";

const Hook = ({ className = "", ...props }) => (
  <div className={`${styles.root} ${className}`.trim()} {...props}>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default Hook;
