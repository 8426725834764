import { useRef, useEffect } from "react";
import Slider from "react-slick";

import styles from "./styles.module.css";
import Button from "../../Components/Button";

import { ReactComponent as PreviousArrowIcon } from "../../assets/previousArrow.svg";
import { ReactComponent as NextArrowIcon } from "../../assets/nextArrow.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";

import CardsImage from "../../assets/logoMedium.png";
import CardsImage2x from "../../assets/logoLarge.png";

import Step1Image from "../../assets/step-1.png";
import Step1Image2x from "../../assets/step-1@2x.png";
import Step2Image from "../../assets/step-2.png";
import Step2Image2x from "../../assets/step-2@2x.png";
import Step3Image from "../../assets/step-3.png";
import Step3Image2x from "../../assets/step-3@2x.png";
import Step4Image from "../../assets/step-4.png";
import Step4Image2x from "../../assets/step-4@2x.png";

import Post1Image from "../../assets/learn-pic-1.jpeg";
import Post1Image2x from "../../assets/learn-pic-1@2x.jpeg";
import Post2Image from "../../assets/defineMeta.jpg";
import Post2Image2x from "../../assets/defineMeta.jpg";
import Post3Image from "../../assets/learn-pic-3.jpeg";
import Post3Image2x from "../../assets/learn-pic-3@2x.jpeg";
import Post4Image from "../../assets/learn-pic-4.jpeg";
import Post4Image2x from "../../assets/learn-pic-4@2x.jpeg";

import "slick-carousel/slick/slick.css";

import LoadingDots from "../../Components/LoadingDots";

import useOnScreen from "../../hooks/useOnScreen";

import ShieldIcon from "../../assets/shield.png";
import ShieldIconAnimated from "../../assets/shield.gif";
import RocketIcon from "../../assets/rocket.png";
import RocketIconAnimated from "../../assets/rocket.gif";
import ScanningIcon from "../../assets/scanning.png";
import ScanningIconAnimated from "../../assets/scanning.gif";
import EmailIcon from "../../assets/email.png";
import EmailIconAnimated from "../../assets/email.gif";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    type="button"
    className={`${className}`}
    style={{ ...style }}
    onClick={onClick}
  >
    <PreviousArrowIcon />
  </button>
);
const NextArrow = ({ className, style, onClick }) => (
  <button
    type="button"
    className={`${className}`}
    style={{ ...style }}
    onClick={onClick}
  >
    <NextArrowIcon />
  </button>
);

const LandingPage = (_) => {
  const scrollButtonRef = useRef(false);
  const secondSectionRef = useRef(false);

  const demoBoxRef = useRef();
  const demoBoxActive = useOnScreen(demoBoxRef);

  useEffect(
    (_) => console.log(`Animation running: ${demoBoxActive ? "yes" : "nope"}`),
    [demoBoxActive]
  );

  return (
    <>
      <div className={`${styles.section} ${styles.jumbotronWrapper}`}>
        <div className={styles.jumbotron}>
          <div className={styles.jumbotronContent}>
            <h1 className={styles.jumbotronTitle}>
              Protect Your Discord Server
            </h1>
            <div className={styles.jumbotronTag}>
              Verify Bot defends your{" "}
              <div className={styles.jumbotronScrollerBox}>
                <div className={styles.jumbotronScroller}>
                  <span>NFT</span>
                  <span>Crypto</span>
                  <span>DAO</span>
                  <span>Community</span>
                </div>
              </div>{" "}
              Discord Server from <b>bot raids</b> and <b>spam</b> using{" "}
              <b>human verification</b>, ensuring your server is <b>safe</b> and{" "}
              <b>secure</b> at all times.
            </div>
            <Button className={styles.jumbotronCTA}>Secure Your Server</Button>
            <Button className={styles.jumbotronCTA} style={{ marginLeft: 12 }}>
              Help
            </Button>
            <a
              ref={scrollButtonRef}
              href="#process"
              className={styles.jumbotronScrollIndicator}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  !secondSectionRef ||
                  !secondSectionRef.current ||
                  !scrollButtonRef ||
                  !scrollButtonRef.current ||
                  scrollButtonRef.current.getBoundingClientRect().top < 75
                )
                  return false;
                document.querySelector("body > #root").scroll({
                  top:
                    secondSectionRef.current.getBoundingClientRect().top || 0,
                  behavior: "smooth",
                });
                return false;
              }}
            >
              <figure className={styles.jumbotronScrollAnimation}>
                <ArrowDown className={styles.jumbotronScrollAnimationArrow} />
                <ArrowDown className={styles.jumbotronScrollAnimationArrow} />
              </figure>
            </a>
          </div>
          <figure
            className={`${styles.jumbotronBackground} ${
              demoBoxActive ? styles.jumbotronBackgroundActive : ""
            }`}
          >
            <figcaption className={styles.jumbotronBackgroundDots}>
              <LoadingDots />
            </figcaption>
            <figcaption className={styles.jumbotronBackgroundSuccess}>
              <span className={styles.jumbotronBackgroundSuccessImage}></span>
              <h1 className={styles.jumbotronBackgroundSuccessTitle}>
                Verification Successful
              </h1>
            </figcaption>
            <figure className={styles.jumbotronBackgroundDemo} ref={demoBoxRef}>
              <figcaption className={styles.jumbotronBackgroundDemoBox}>
                <figcaption
                  className={styles.jumbotronBackgroundDemoCursor}
                ></figcaption>
                <figcaption
                  className={styles.jumbotronBackgroundDemoCheck}
                ></figcaption>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 44 44"
                  style={{ width: 30, height: 30, display: "block" }}
                  className={styles.jumbotronBackgroundDemoBoxPulse}
                >
                  <g>
                    <circle
                      style={{
                        fill: "none",
                        stroke: "#FF7B00",
                        strokeWidth: 2,
                      }}
                      cx="22"
                      cy="22"
                      r="1"
                    >
                      <animate
                        accumulate="none"
                        additive="replace"
                        attributeName="r"
                        begin="0s"
                        calcMode="spline"
                        dur="1.8s"
                        fill="remove"
                        keySplines="0.165, 0.84, 0.44, 1"
                        keyTimes="0; 1"
                        repeatCount="indefinite"
                        restart="always"
                        values="1; 20"
                      ></animate>
                      <animate
                        accumulate="none"
                        additive="replace"
                        attributeName="stroke-opacity"
                        begin="0s"
                        calcMode="spline"
                        dur="1.8s"
                        fill="remove"
                        keySplines="0.3, 0.61, 0.355, 1"
                        keyTimes="0; 1"
                        repeatCount="indefinite"
                        restart="always"
                        values="1; 0"
                      ></animate>
                    </circle>
                    <circle
                      style={{
                        fill: "none",
                        stroke: "#FF7B00",
                        strokeWidth: 2,
                      }}
                      cx="22"
                      cy="22"
                      r="1"
                    >
                      <animate
                        accumulate="none"
                        additive="replace"
                        attributeName="r"
                        begin="-0.9s"
                        calcMode="spline"
                        dur="1.8s"
                        fill="remove"
                        keySplines="0.165, 0.84, 0.44, 1"
                        keyTimes="0; 1"
                        repeatCount="indefinite"
                        restart="always"
                        values="1; 20"
                      ></animate>
                      <animate
                        accumulate="none"
                        additive="replace"
                        attributeName="stroke-opacity"
                        begin="-0.9s"
                        calcMode="spline"
                        dur="1.8s"
                        fill="remove"
                        keySplines="0.3, 0.61, 0.355, 1"
                        keyTimes="0; 1"
                        repeatCount="indefinite"
                        restart="always"
                        values="1; 0"
                      ></animate>
                    </circle>
                  </g>
                </svg>
              </figcaption>
              <figcaption className={styles.jumbotronBackgroundDemoCaption}>
                I am human
              </figcaption>
            </figure>
            <img
              className={styles.jumbotronBackgroundImage}
              srcSet={`${CardsImage2x} 2x`}
              src={CardsImage}
              alt="Cards"
              style={{ padding: "8vw" }}
            />
          </figure>
          <div className={styles.jumbotronCards}>
            <div className={styles.jumbotronCard}>
              <figure className={styles.jumbotronCardHeader}>
                <figure
                  className={styles.jumbotronCardIcon}
                  style={{
                    "--static": `url(${ShieldIcon})`,
                    "--animated": `url(${ShieldIconAnimated})`,
                  }}
                ></figure>
                <figcaption className={styles.jumbotronCardHeaderTitle}>
                  Secure
                </figcaption>
              </figure>
              <div className={styles.jumbotronCardDetails}>
                Our dynamic challenges are generated live and automatically
                refresh frequently to prevent brute force attacks.
              </div>
            </div>
            <div className={styles.jumbotronCard}>
              <figure className={styles.jumbotronCardHeader}>
                <figure
                  className={styles.jumbotronCardIcon}
                  style={{
                    "--static": `url(${RocketIcon})`,
                    "--animated": `url(${RocketIconAnimated})`,
                  }}
                ></figure>
                <figcaption className={styles.jumbotronCardHeaderTitle}>
                  Fast
                </figcaption>
              </figure>
              <div className={styles.jumbotronCardDetails}>
                Verifications take less than 30 seconds on average. Setup takes
                less than 5 minutes using our new-user friendly walkthrough.
              </div>
            </div>
            <div className={styles.jumbotronCard}>
              <figure className={styles.jumbotronCardHeader}>
                <figure
                  className={styles.jumbotronCardIcon}
                  style={{
                    "--static": `url(${ScanningIcon})`,
                    "--animated": `url(${ScanningIconAnimated})`,
                  }}
                ></figure>
                <figcaption className={styles.jumbotronCardHeaderTitle}>
                  Smart
                </figcaption>
              </figure>
              <div className={styles.jumbotronCardDetails}>
                Our AI automatically recognizes suspicious / bot accounts and
                remove them or flag for manual review at your convenience.
              </div>
            </div>
            <div className={styles.jumbotronCard}>
              <figure className={styles.jumbotronCardHeader}>
                <figure
                  className={styles.jumbotronCardIcon}
                  style={{
                    "--static": `url(${EmailIcon})`,
                    "--animated": `url(${EmailIconAnimated})`,
                  }}
                ></figure>
                <figcaption className={styles.jumbotronCardHeaderTitle}>
                  Safe
                </figcaption>
              </figure>
              <div className={styles.jumbotronCardDetails}>
                The bot will <b style={{ color: "#67C9CB" }}>NEVER</b> DM your
                users as our verification process does not require messaging. We
                abide by the same best practices as you.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.section}`}>
        <div className={styles.process} ref={secondSectionRef}>
          <div className={styles.processHeader}>
            <h2 className={styles.processHeaderTitle}>
              How Verification Works
            </h2>
            <div className={styles.processHeaderTag}>
              Our process is designed to be fast, straightforward, and painless
              for humans and excruciatingly painful for bots.{" "}
              <b>We do not DM users for verification.</b>
            </div>
          </div>
          <div className={`${styles.processList} ${styles.noScrollBar}`}>
            <div className={styles.processListItem}>
              <figure className={styles.processListItemIcon}>
                <img
                  srcSet={`${Step1Image2x} 2x`}
                  src={Step1Image}
                  alt="Step 1"
                  className={styles.processListItemIconImage}
                />
              </figure>
              <div className={styles.processListItemNumber}>Step 1</div>
              <div className={styles.processListItemTitle}>Join</div>
              <div className={styles.processListItemDescription}>
                User joins your Discord Server and is pinged in the verification
                to notify them about the additional step required to partipate
                in your community.
              </div>
            </div>
            <div className={styles.processListItem}>
              <figure className={styles.processListItemIcon}>
                <img
                  srcSet={`${Step2Image2x} 2x`}
                  src={Step2Image}
                  alt="Step 2"
                  className={styles.processListItemIconImage}
                />
              </figure>
              <div className={styles.processListItemNumber}>Step 2</div>
              <div className={styles.processListItemTitle}>Start</div>
              <div className={styles.processListItemDescription}>
                User presses on the verification button and a ephemeral message
                (<b>NOT A DM</b>) pops up, with a verification link. User
                follows the verification link.
              </div>
            </div>
            <div className={styles.processListItem}>
              <figure className={styles.processListItemIcon}>
                <img
                  srcSet={`${Step3Image2x} 2x`}
                  src={Step3Image}
                  alt="Step 3"
                  className={styles.processListItemIconImage}
                />
              </figure>
              <div className={styles.processListItemNumber}>Step 3</div>
              <div className={styles.processListItemTitle}>Challenge</div>
              <div className={styles.processListItemDescription}>
                User completes a quick, painless challenge to prove their
                humanity. Our AI checks the user's account for any red flags and
                if not, verifies them.
              </div>
            </div>
            <div className={styles.processListItem}>
              <figure className={styles.processListItemIcon}>
                <img
                  srcSet={`${Step4Image2x} 2x`}
                  src={Step4Image}
                  alt="Step 4"
                  className={styles.processListItemIconImage}
                />
              </figure>
              <div className={styles.processListItemNumber}>Step 4</div>
              <div className={styles.processListItemTitle}>Success</div>
              <div className={styles.processListItemDescription}>
                Verification is now complete and user is prompted to return to
                Discord, where the bot will (optionally) welcome them to your
                community.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div
        className={`${styles.section} ${styles.blogWrapper} aQYG7gEJarjEJMaU`}
      >
        <div className={styles.blog}>
          <div className={styles.blogInformation}>
            <div className={styles.blogHeader}>
              <h2 className={styles.blogHeaderTitle}>Major Adopters</h2>
              <div
                className={`${styles.blogHeaderNavigation} ${styles.noScrollBar}`}
              >
                <button className={styles.blogHeaderNavigationTab} disabled>
                  All
                </button>
                <button className={styles.blogHeaderNavigationTab}>NFT</button>
                <button className={styles.blogHeaderNavigationTab}>
                  Crypto
                </button>
                <button className={styles.blogHeaderNavigationTab}>DAO</button>
              </div>
            </div>
            <div className={styles.blogCTA}>
              <Button
                href="/users"
                borderColor="#E6E8EC"
                backgroundColor="#FCFCFD"
                textColor="#24262E"
                fillTextColor="#FCFCFD"
                fillColor="#24262E"
                className={styles.blogCTAButton}
                shadow={false}
              >
                View More
              </Button>
            </div>
          </div>
          <div className={styles.blogPosts}>
            <Slider
              {...{
                className: styles.blogPostsSlider,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                speed: 500,
                infinite: true,
                adaptiveHeight: true,
                prevArrow: <PrevArrow />,
                nextArrow: <NextArrow />,
                responsive: [
                  {
                    breakpoint: 100000,
                    settings: "unslick",
                  },
                  {
                    breakpoint: 1179,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ],
              }}
            >
              <div className={styles.blogPost} href="/blog">
                <div className={styles.blogPostPreview}>
                  <img
                    className={styles.blogPostPreviewImage}
                    srcSet={`${Post1Image2x} 2x`}
                    src={Post1Image}
                    alt="Card"
                  />
                </div>
                <div className={styles.blogPostLine}>
                  <div className={styles.blogPostWrap}>
                    <div className={styles.blogPostSubtitle}>
                      Bitcoin Investing 101
                    </div>
                    <div className={styles.blogPostContent}>
                      Learn Bitcoin investing in easy steps!
                    </div>
                  </div>
                  <Button
                    style={{
                      flexShrink: 0,
                      marginLeft: 32,
                      height: 48,
                      padding: "0 24px",
                      fontSize: 16,
                    }}
                    shadow={false}
                    backgroundColor="#FCFCFD"
                    borderColor="#E6E8EC"
                    fillColor="#23262F"
                    textColor="#23262F"
                    fillTextColor="#FCFCFD"
                    className={styles.blogPostCTA}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
              <a
                className={styles.blogPost}
                href="https://twitter.com/definemeta"
                target="_blank"
              >
                <div className={styles.blogPostPreview}>
                  <img
                    className={styles.blogPostPreviewImage}
                    srcSet={`${Post2Image2x} 2x`}
                    src={Post2Image}
                    alt="Card"
                  />
                </div>
                <div className={styles.blogPostDetails}>
                  <div className={styles.blogPostSubtitle}>DeFiNE Meta</div>
                  <div className={styles.blogPostContent}>
                    DeFiNE Meta is one of the biggest names in the NFT space on
                    Twitter. They are fervent users of Verify Bot in their
                    private Discord Server.
                  </div>
                  <a className={styles.blogPostDate}>86k Twitter Followers</a>
                </div>
              </a>
              <a className={styles.blogPost} href="/blog">
                <div className={styles.blogPostPreview}>
                  <img
                    className={styles.blogPostPreviewImage}
                    srcSet={`${Post3Image2x} 2x`}
                    src={Post3Image}
                    alt="Card"
                  />
                </div>
                <div className={styles.blogPostDetails}>
                  <div className={styles.blogPostSubtitle}>
                    How Do I Promote My NFT Collection?
                  </div>
                  <div className={styles.blogPostContent}>
                    Need help getting your NFT Collection off the ground? Find
                    out how to promote your project using quick and painless
                    methods!
                  </div>
                  <div className={styles.blogPostDate}>Jan 1, 2023</div>
                </div>
              </a>
              <a className={styles.blogPost} href="/blog">
                <div className={styles.blogPostPreview}>
                  <img
                    className={styles.blogPostPreviewImage}
                    srcSet={`${Post4Image2x} 2x`}
                    src={Post4Image}
                    alt="Card"
                  />
                </div>
                <div className={styles.blogPostDetails}>
                  <div className={styles.blogPostSubtitle}>
                    Top 10 Best Cryptocurrency &amp; NFT Wallets 2022
                  </div>
                  <div className={styles.blogPostContent}>
                    Our Top 10 Picks for The Best Cryptocurrency wallets, 2022
                    edition.
                  </div>
                  <div className={styles.blogPostDate}>Jan 1, 2023</div>
                </div>
              </a>
            </Slider>
                  </div>
        </div>
      </div>*/}
    </>
  );
};

export default LandingPage;
/*
<a class="learn__item" href="learn-crypto-details.html">
                <div class="learn__preview">
                  <img
                    srcSet="img/content/learn-pic-1@2x.jpg 2x"
                    src="img/content/learn-pic-1.jpg"
                    alt="Card"
                  />
                </div>
                <div class="learn__line">
                  <div class="learn__wrap">
                    <div class="learn__subtitle">
                      Leveraged tokens now available
                    </div>
                    <div class="learn__content">
                      Good things come in 3s. Get 3x Leveraged tokens now.
                    </div>
                  </div>
                  <button class="button-stroke learn__button">
                    <span>Learn more</span>
                    <svg class="icon icon-arrow-right"></svg>
                  </button>
                </div>
              </a>
              <a class="learn__item" href="learn-crypto-details.html">
                <div class="learn__preview">
                  <img
                    srcSet="img/content/learn-pic-2@2x.jpg 2x"
                    src="img/content/learn-pic-2.jpg"
                    alt="Card"
                  />
                </div>
                <div class="learn__details">
                  <div class="learn__subtitle">
                    Leveraged tokens now available
                  </div>
                  <div class="learn__content">
                    Good things come in 3s. Get 3x Leveraged tokens now.
                  </div>
                  <div class="learn__date">Jun 1, 2021</div>
                </div>
              </a>
              <a class="learn__item" href="learn-crypto-details.html">
                <div class="learn__preview">
                  <img
                    srcSet="img/content/learn-pic-4@2x.jpg 2x"
                    src="img/content/learn-pic-4.jpg"
                    alt="Card"
                  />
                  <button class="play play_small">
                    <svg class="icon icon-play"></svg>
                  </button>
                </div>
                <div class="learn__details">
                  <div class="learn__subtitle">
                    Leveraged tokens now available
                  </div>
                  <div class="learn__content">
                    Good things come in 3s. Get 3x Leveraged tokens now.
                  </div>
                  <div class="learn__date">Jun 1, 2021</div>
                </div>
              </a>
              <a class="learn__item" href="learn-crypto-details.html">
                <div class="learn__preview">
                  <img
                    srcSet="img/content/learn-pic-3@2x.jpg 2x"
                    src="img/content/learn-pic-3.jpg"
                    alt="Card"
                  />
                </div>
                <div class="learn__details">
                  <div class="learn__subtitle">
                    Leveraged tokens now available
                  </div>
                  <div class="learn__content">
                    Good things come in 3s. Get 3x Leveraged tokens now.
                  </div>
                  <div class="learn__date">Jun 1, 2021</div>
                </div>
              </a>
*/
