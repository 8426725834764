import styles from "./styles.module.css";

const Button = ({
  children = null,
  borderColor = "var(--theme)",
  backgroundColor = "var(--theme)",
  textColor = "var(--white)",
  fillColor = "var(--white)",
  fillTextColor = "var(--theme)",
  className = "",
  shadow = true,
  style = {},
  ...props
}) => (
  <a
    className={`${className} ${styles.root} ${
      !shadow ? "" : styles.shadow
    }`.trim()}
    style={{
      "--borderColor": borderColor,
      "--backgroundColor": backgroundColor,
      "--textColor": textColor,
      "--fillColor": fillColor,
      "--fillTextColor": fillTextColor,
      ...style,
    }}
    {...props}
  >
    {children || null}
  </a>
);

export default Button;
