import styles from "./styles.module.css";
import LogoIcon from "../../assets/logo.png";
import Button from "../Button";

const Header = (_) => {
  return (
    <header className={styles.root}>
      <nav className={styles.wrapper}>
        <figure className={styles.logo}>
          <img src={LogoIcon} className={styles.logoImage} alt="Our logo" />
          <figcaption className={styles.logoCaption}>Verify Bot</figcaption>
        </figure>
        <nav className={styles.nav}>
          <div className={styles.navLinks}>
            <a href="/" className={styles.navLink}>
              Home
            </a>
            <a href="/start" className={styles.navLink}>
              Get Started
            </a>
            <a
              href="https://discord.gg/contact"
              className={styles.navLink}
              target="_blank"
            >
              Help
            </a>
          </div>
          <div className={styles.cta}>
            <Button href="/login">Login</Button>
          </div>
        </nav>
      </nav>
    </header>
  );
};

export default Header;
