import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";

import styles from "./styles.module.css";

import Header from "./Components/Header";
import Footer from "./Components/Footer";

import LandingPage from "./Pages/LandingPage";

const App = () => {
  /*const [userToken, setUserToken] = useState(
    (window.localStorage.getItem("a") || "").trim()
  );
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  useEffect(
    (_) => {
      if (typeof userToken !== "string" || userToken.trim() === "") {
        setIsLoggedIn(false);
        setIsLoadingUserData(false);
        setUserData(null);
        return;
      }

      const abortController = new AbortController();
      fetch("/api/user", { signal: abortController.signal }).then((r) =>
        r.json()
      );

      return (_) => abortController.abort();
    },
    [userToken]
  );

  const props = {
    user: {
      token: userToken,
      data: userData,
      isLoggedIn: isLoggedIn,
      isLoadingUserData: isLoadingUserData,
    },
  };*/

  return (
    <>
      <BrowserRouter>
        <Header />
        <main className={styles.page}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/verify/:token" element={<LandingPage />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
