import styles from "./styles.module.css";
import Logo from "../../assets/logo.png";
import Button from "../Button";

import { ReactComponent as DiscordIcon } from "../../assets/discord.svg";
import { ReactComponent as TwitchIcon } from "../../assets/twitch.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as TiktokIcon } from "../../assets/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/youtube.svg";

const Footer = (_) => (
  <footer className={styles.root}>
    <div className={styles.bodyWrapper}>
      <div className={styles.body}>
        <div className={styles.column}>
          <figure className={styles.logo}>
            <img src={Logo} alt="Our logo" />
          </figure>
          <div className={styles.columnWrapper}>
            <div className={styles.columnHeading}>Navigation</div>
            <div className={styles.columnLinks}>
              <a href="/">Home</a>
              <a href="/start">Get Started</a>
              <a href="https://discord.gg/contact" target="_blank">
                Help
              </a>
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnHeading}>Contact</div>
          <div className={styles.columnInfo}>
            <p>Kalkofnsvegur 2</p>
            <p>Reykjavik, Capital Region</p>
            <p>Iceland 101</p>
            <p>+354.4212434</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnHeading}>Get Started</div>
          <div className={styles.columnInfo}>
            Verify Bot defends your Discord Server from bot raids and spam using
            human verification, ensuring your server is safe and secure at all
            times.
            <Button
              style={{ marginTop: 24, height: 48, width: "100%" }}
              shadow={false}
              borderColor="#E6E8EC"
              backgroundColor="white"
              fillColor="var(--theme)"
              textColor="#23262F"
              fillTextColor="#E6E8EC"
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.taglineWrapper}>
      <div className={styles.tagline}>
        <div className={styles.copyright}>
          Copyright &copy; 2021-{new Date().getFullYear()}{" "}
          <a
            href="https://byiei.com/"
            target="_blank"
            style={{ color: "inherit" }}
          >
            IEI Global Holdings Joint Stock Co
          </a>
          . All rights reserved.
        </div>
        <div className={styles.socials}>
          <a
            href="https://discord.gg/contact"
            className={styles.socialLink}
            target="_blank"
          >
            <DiscordIcon />
          </a>
          {/*<a href="/" className={styles.socialLink}>
            <TwitchIcon />
          </a>*
          <a href="/" className={styles.socialLink}>
            <TwitterIcon />
          </a>
          <a href="/" className={styles.socialLink}>
            <TiktokIcon />
          </a>
          <a href="/" className={styles.socialLink}>
            <YoutubeIcon />
        </a>*/}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
